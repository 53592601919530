
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import CustomSwitch from '@/modules/common/components/ui-kit/custom-switch.vue';
import Dialog from '@/modules/common/components/ui-kit/dialog.vue';
import type HotelsService from '@/modules/hotels/hotels.service';
import type UserService from '@/modules/user/user.service';
import ReportsService, { ReportsServiceS } from '@/modules/reports/reports.service';
import { DATA_TYPE } from '@/modules/reports/constants';
import ScheduledItemModel from '@/modules/reports/models/scheduled-item.model';
import { USER_LEVELS } from '@/modules/user/constants';

@Component({
    components: {
        CustomMultiSelect,
        CustomSelect,
        CustomSwitch,
        Dialog,
    },
})
export default class ScheduledReportsSection extends Vue {
    @inject(ReportsServiceS) private reportsService!: ReportsService;
    @inject(KEY.UserService) private userService!: UserService;
    @inject(KEY.HotelsService) private hotelsService!: HotelsService;

    isClusterMode = false;
    isRemoving = false;

    filtersNeedsToReset = true;
    reportToDelete: string | null = null;

    private lastHotelId: number | null = null;

    frequencyLabel = {
        weekly: this.$tc('frequency.weekly'),
        monthly: this.$tc('frequency.monthly'),
        daily: this.$tc('frequency.daily'),
        yearly: this.$tc('frequency.yearly'),
    } as Record<string, string>;

    filters = {
        type: [] as string[],
        frequency: null as string | null,
        dateRange: null as number | null,
    };

    beforeMount() {
        this.reportsService.resetReports();
    }

    beforeDestroy() {
        this.reportsService.resetReports();
    }

    get isLoading() {
        return this.reportsService.loading.isLoading();
    }

    get hotelName() {
        return this.hotelsService.hotelNames[this.$route.params.hotelId];
    }

    get hotelId() {
        return this.userService.currentHotelId;
    }

    get isViewAsDefined() {
        return !!this.hotelId && this.userService.user?.level !== USER_LEVELS.HOTEL;
    }

    get typeItems() {
        const types = this.reports.map(report => report.type);
        const uniqueTypes = [...new Set(types)];

        if (!this.isLoading) {
            const haveNonExistsType = this.filters.type.some(type => !uniqueTypes.includes(type));

            if (haveNonExistsType) {
                this.filters.type = this.filters.type.filter(type => uniqueTypes.includes(type));

                if (!this.filters.type.length) {
                    this.filters.type = uniqueTypes;
                }
            }
        }

        const items = uniqueTypes
            .sort((a, b) => a.localeCompare(b))
            .map(type => ({
                value: type,
                name: this.$tc(`scheduledReports.type.${type}`),
            }));

        if (this.lastHotelId !== this.hotelId && !this.isLoading) {
            this.lastHotelId = this.hotelId;
            this.filters.type = items.map(i => i.value);
        }

        return items;
    }

    get frequencyItems() {
        const frequencies = this.reports.map(report => report.schedulerConfig?.type) as (string | null)[];
        const uniqueFrequencies = [...new Set(frequencies)];
        const itemOrder = ['daily', 'weekly', 'monthly', 'yearly'];

        uniqueFrequencies.splice(0, 0, null);

        if (!this.isLoading) {
            const haveNonExistsFrequency = !uniqueFrequencies.includes(this.filters.frequency);

            if (haveNonExistsFrequency) {
                [this.filters.frequency] = uniqueFrequencies;
            }
        }

        return uniqueFrequencies
            .sort((a, b) => {
                const aIndex = itemOrder.indexOf(a || '');
                const bIndex = itemOrder.indexOf(b || '');

                return aIndex - bIndex;
            })
            .map(frequency => ({
                value: frequency === null ? null : frequency,
                name: frequency === null ? this.$tc('filters.all') : this.frequencyLabel[frequency],
            }));
    }

    get dateRangeItems() {
        const ranges = this.reports.map(report => report.numberOfDays) as (number | null)[];
        const uniqueFrequencies = [...new Set(ranges)];

        uniqueFrequencies.splice(0, 0, null);

        if (!this.isLoading) {
            const haveNonExistsRange = !uniqueFrequencies.includes(this.filters.dateRange);

            if (haveNonExistsRange) {
                [this.filters.dateRange] = uniqueFrequencies;
            }
        }

        return uniqueFrequencies
            .sort((a, b) => (a || 0) - (b || 0))
            .map(frequency => ({
                value: frequency === null ? null : frequency,
                name: frequency === null ? this.$tc('filters.all') : (frequency || '-'),
            }));
    }

    get filteredReports() {
        const { reports } = this;

        if (this.isLoading) {
            const dummyItem = new ScheduledItemModel();
            dummyItem.type = '';
            dummyItem.numberOfDays = 30;
            dummyItem.recipients = new Array(3).fill('null');
            dummyItem.nextExport = new Date();
            dummyItem.userId = 'loading';

            return new Array(10).fill(dummyItem);
        }

        if (this.filtersNeedsToReset) {
            this.filtersNeedsToReset = false;
            this.resetFilters();
        }

        const { frequency, type, dateRange } = this.filters;

        if (!reports) return [];

        return this.reports
            .filter(report => {
                if (dateRange !== null && report.numberOfDays !== dateRange) return false;
                if (!type.includes(report.type)) return false;
                if (frequency !== null && report.schedulerConfig.type !== frequency) return false;

                return true;
            })
            .sort((a, b) => (a.createdDate > b.createdDate ? -1 : 1));
    }

    formatDate(date: Date | null) {
        if (!date) {
            return '-';
        }
        const d = new Date(date);
        const day = (`0${d.getUTCDate()}`).slice(-2);
        const month = (`0${d.getUTCMonth() + 1}`).slice(-2);
        return `${day}/${month}/${d.getUTCFullYear()}`;
    }

    changeCluserMode(mode: boolean) {
        this.isClusterMode = mode;
        this.reportsService.resetReports();
        this.filtersNeedsToReset = true;
    }

    openReportToEdit(report: ScheduledItemModel) {
        const type = report.type.toLowerCase().replace(/\s/g, '-');
        const typePrefix = this.isClusterMode ? 'cluster-' : '';

        this.$router.push({
            name: `${this.$route.name!}.${typePrefix}${type}-report-edit`,
            params: {
                ...this.$route.params,
                reportId: report.id,
            },
        });
    }

    async deleteReport(reportId: string | null) {
        if (!this.reportToDelete) {
            this.reportToDelete = reportId;
            return;
        }

        this.isRemoving = true;
        try {
            await this.reportsService.deleteReport(this.reportToDelete);
            this.reportToDelete = null;
        } finally {
            this.isRemoving = false;
        }
    }

    private get reports() {
        const type = this.isClusterMode ? DATA_TYPE.CLUSTER_ALL : DATA_TYPE.HOTEL_ALL;
        return this.reportsService.getReports(type) || [];
    }

    private resetFilters() {
        if (!this.reports) return;

        this.filters.type = this.typeItems.map(item => item.value);
        this.filters.frequency = null;
    }
}
